import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import XIcon from '@mui/icons-material/X';
import logo from "./img/logo2.jpg";

const Navbar = () => {
    const [expanded, setExpanded] = useState(false);

    const handleNavItemClick = () => {
        setExpanded(false);
    };

    return (
        <>
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <Link to="/" className="navbar-brand" >
                       <b style={{marginLeft:'-35px',fontSize:'18px'}}> VARDHAN  HOSPITAL TRAUMA <br/> & DIAGNOSTIC  CENTER</b>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={expanded} aria-label="Toggle navigation" onClick={() => setExpanded(!expanded)}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`collapse navbar-collapse ${expanded ? 'show' : ''}`} id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link to="/" className="nav-link" onClick={handleNavItemClick}>Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/About" className="nav-link" onClick={handleNavItemClick}>About</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false">Facilities</Link>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Advanced Medical Infrastructure</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Expert Team Of Specialists</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Get Treated By The Best, Best Care Of Patients</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Dermatology, Plastic & Cosmetic Surgery</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Trusted Care From Leading Specialists</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Highly Trained Staff ENT</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Advanced Technology</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Best I.C.U Facility</Link></li>

                                    <p style={{paddingLeft:'10px'}}>दूरबीन विधि द्वारा टूटे तंतुओं की निर्माण। • घुटना एवं कुल्हे का प्रत्यारोपण
                        C-ARM द्वारा टूटी हड्डियों का इलाज हड्‌डी की टी.बी. (T.B.) का इलाज
                        टूटी हड्डियों पर प्लास्टर की सुविधा रीढ़ की हड्डी (SPINE) का ऑपरेशन*
                        • संक्रमण (Infection) का इलाज गठिया (ARTHRITIS) जोड़ों का दर्द • (JOINT PAIN) कमर दर्द (BACK PAIN) का इलाज</p>
                                </ul>
                            </li>

                            <li className="nav-item dropdown">
                                <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-expanded="false">Treatment</Link>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown2">
                                    <li><Link to="/Generlchp" className="dropdown-item" onClick={handleNavItemClick}>General Checkups, Psychiatry</Link></li>
                                    <li><Link to="/Generalsurgy" className="dropdown-item" onClick={handleNavItemClick}>General Surgery</Link></li>
                                    <li><Link to="/LaparoscopicSurgery" className="dropdown-item" onClick={handleNavItemClick}>Laparoscopic Surgery</Link></li>
                                    <li><Link to="/CancerSurgery" className="dropdown-item" onClick={handleNavItemClick}>Cancer Surgery</Link></li>
                                    <li><Link to="/Chemotherapy" className="dropdown-item" onClick={handleNavItemClick}>Chemotherapy</Link></li>
                                    <li><Link to="/Gastroenterology" className="dropdown-item" onClick={handleNavItemClick}>Gastroenterology</Link></li>
                                    <li><Link to="/Maternity" className="dropdown-item" onClick={handleNavItemClick}>Maternity Care</Link></li>
                                    <li><Link to="/Gynaecology" className="dropdown-item" onClick={handleNavItemClick}>Gynaecology & Obstetrics</Link></li>
                                    <li><Link to="/Neurology" className="dropdown-item" onClick={handleNavItemClick}>Neurology</Link></li>
                                    <li><Link to="/Orthopedics" className="dropdown-item" onClick={handleNavItemClick}>Orthopedics</Link></li>
                                    <li><Link to="/Urology" className="dropdown-item" onClick={handleNavItemClick}>Urology, Nephrology</Link></li>
                                    <li><Link to="/Ambulance" className="dropdown-item" onClick={handleNavItemClick}>Ambulance Service</Link></li>
                                </ul>
                            </li>

                            <li className="nav-item">
                                <Link to="/" className="nav-link" onClick={handleNavItemClick}>Our Staff</Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/Gallery" className="nav-link" onClick={handleNavItemClick}>Gallery</Link>
                            </li>
                           
                        
                            <li className="nav-item">
                                <Link to="/Data" className="nav-link" onClick={handleNavItemClick}>View Data</Link>
                            </li>

                           

                            <li className="nav-item">
                                <Link to="/Register" className="nav-link" onClick={handleNavItemClick}> Appointment</Link>
                            </li>
                            
                        </ul>

                        
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Navbar;
