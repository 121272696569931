import React from 'react'
import { Link } from 'react-router-dom'
import about from './img/post3.jpg'
import about2 from './img/post6.jpg'
import about3 from './img/dr.jpg'

const About = () => {
    return (

        <>
            <div className='about'>
                <div className='about2'>

                    <h1>WELCOME TO  VARDAN HOSPITAL TRAUMA AND DIAGNOSTIC CENTER </h1>
                    <p>VARDAN HOSPITAL, a leading multi-super Speciality hospital in Lucknow committed to providing comprehensive and high-quality healthcare services. Our facility is equipped with state-of-art technology and our team of highly skilled medical professionals is dedicated to delivering exceptional patient care. Our charges is also very affordable.
                        "We at VARDAN are COMMITTED to achieve EXCELLENCE in QUALITY of services and STRIVE for continuous improvement for providing best health care services and complete customer satisfaction.</p>


                    <Link to='/Register'><button className="btn btn-primary">Contact US </button></Link>

                </div>
                <br />
                <div className='about2'>
                    <img src={about} />
                </div>
            </div>

            <hr />
            <br />

            <div className='about'>
                <div className='about2'>

                    <h1>WELCOME TO DR. VARUN SINGH </h1>


                    <p><b>MBBS MS ORTHOPAEDICS</b></p>
                    <p>हड्डी, जोड़ एवं नस रोग विशेषज्ञ</p>
                    <p>Trauma& Joint  Replacement Surgeon Ex Consultant MIMS  Lucknow  Member  IMA UPOA</p>

                    {/* <p>दूरबीन विधि द्वारा टूटे तंतुओं की निर्माण। • घुटना एवं कुल्हे का प्रत्यारोपण
                        C-ARM द्वारा टूटी हड्डियों का इलाज हड्‌डी की टी.बी. (T.B.) का इलाज
                        टूटी हड्डियों पर प्लास्टर की सुविधा रीढ़ की हड्डी (SPINE) का ऑपरेशन*
                        • संक्रमण (Infection) का इलाज गठिया (ARTHRITIS) जोड़ों का दर्द • (JOINT PAIN) कमर दर्द (BACK PAIN) का इलाज</p> */}
                </div>
                <br />
                <div className='about2'>
                    <img src={about2} />
                </div>
            </div>


            <hr />
            <br />

            <div className='about'>
                <div className='about2'>

                    <h1>  Welcome to Dr. Akanksha singh </h1>


                    <p><b>MBBS MS (Obs Gyno)</b></p>
                    <p>प्रसूति एवं स्त्री रोग विशेषज्ञ</p>
                    <p> चिकित्साधिकारी , सामुदायिक स्वास्थ केन्द्र शाहगंज जौनपुर Ex. Resident Sahara Hospital Lucknow</p>

                    <p>(मेयो मेडिकल कालेज एण्ड हास्पिटल) लखनऊ </p>
                </div>
                <br />
                <div className='about2'>
                    <img src={about3} />
                </div>
            </div>



            {/* <div className='emergncy'>
                <h1>OPEN 24*7  HRS [ EMERGENCY SERVICES ]</h1>
            </div> */}

            <div class="icon-bar">
                <a href="#" class="facebook"><i class="fa fa-facebook"></i></a>
                <a href="#" class="twitter"><i class="fa fa-instagram"></i></a>
                <a href="#" class="google"><i class="fa fa-whatsapp"></i></a>
                <a href="#" class="youtube"><i class="fa fa-youtube-play"></i></a>
            </div>


        </>
    )
}

export default About